'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:DispatchUnitTypes

 # @description

###
angular
  .module 'lpaData'
  .factory 'DispatchUnitTypes', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/dispatch/units/types')
    ]
